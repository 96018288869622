import css from "./header-slogan-item.module.css";
import React from "react";

export const HeaderSloganItem = ({ text }) => {
  return (
    <li className={css["header-slogan-item"]}>
      <p className={css["header-slogan-text"]}>{text}</p>
    </li>
  );
};
