import clsx from "clsx";
import css from "./Loader.module.css";

export const Loader = ({ className = "pageloader" }) => {
  return (
    <div className={clsx(css["pageloader"], css[className])}>
      <div className={css["loader"]}></div>
    </div>
  );
};
