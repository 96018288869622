import css from "./lang-selector.module.css";
import svg from "../../../img/sprite.svg";
import { useRef, useState } from "react";
import clsx from "clsx";
import { nanoid } from "nanoid";
import React from "react";
import { useLanguage } from "../../../hooks/useLanguage.js";
import { useClickOutside } from "../../../hooks/useClickOutSide.js";
import { setLanguage } from "../../../redux/app/slice.js";
import { useDispatch } from "react-redux";

export const LangSelector = () => {
  const { language } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const langRef = useRef(null);
  const dispatch = useDispatch();

  useClickOutside(langRef, () => setIsOpen(false));

  const languagesList = [
    { value: "ua", label: "Українська", icon: "#ua-flag" },
    { value: "en", label: "English", icon: "#gb-flag" },
  ];

  const curLangData = languagesList.find((lang) => lang.value === language) || {
    value: "en",
    label: "English",
    icon: "#gb-flag",
  };

  const chooseLang = (chooseLang) => {
    dispatch(setLanguage(chooseLang));
    setIsOpen(false);
  };

  return (
    <div className={css["container"]}>
      <button
        type="button"
        className={css["select-btn"]}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <svg width="50" height="30" className={css["cur-lang-icon"]}>
          <use href={`${svg}${curLangData.icon}`}></use>
        </svg>
      </button>
      <div
        ref={langRef}
        className={clsx(
          css["lang-list-container"],
          !isOpen && css["is-hidden"]
        )}
      >
        <ul className={css["lang-list"]}>
          {languagesList.map((lang) => {
            return (
              <li key={nanoid()} className={css["lang-item"]}>
                <button
                  type="button"
                  className={css["lang-item-btn"]}
                  onClick={() => {
                    chooseLang(lang.value);
                  }}
                >
                  <svg width="50" height="30" className={css["lang-item-icon"]}>
                    <use
                      className={css["lang-item-icon-use"]}
                      href={`${svg}${lang.icon}`}
                    ></use>
                  </svg>
                  {lang.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
