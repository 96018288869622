import css from "./footer-form.module.css";
import React, { Component } from "react";

const INITIAL_STATE = {
  name: "",
  email: "",
  message: "",
};

class FooterForm extends Component {
  state = { ...INITIAL_STATE };

  handleChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  };

  reset = () => {
    this.setState({ ...INITIAL_STATE });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    const name = form.elements.name.value;
    const email = form.elements.email.value;
    const message = form.elements.message.value;
    this.props.onSubmit({ name, email, message });
    this.reset();
  };

  render() {
    const { name, email, message } = this.state;
    const { form_label, name_label, email_label, message_label, button_label } =
      this.props.content;
    return (
      <form
        onSubmit={this.handleSubmit}
        action="submit"
        className={css["contacts-form"]}
      >
        <fieldset className={css["contacts-form-block"]}>
          <legend className={css["contacts-form-label"]}>{form_label}</legend>
          <div className={css["input-container"]}>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.handleChange}
              required
            />
            <label htmlFor="name">{name_label}</label>
          </div>
          <div className={css["input-container"]}>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              required
            />
            <label htmlFor="email">{email_label}</label>
          </div>
          <div className={css["input-container"]}>
            <textarea
              name="message"
              id="message"
              value={message}
              cols="1"
              rows="1"
              onChange={this.handleChange}
              required
            ></textarea>
            <label htmlFor="message">{message_label}</label>
          </div>
        </fieldset>
        <button type="submit" className={css["form-btn"]}>
          {button_label}
        </button>
      </form>
    );
  }
}

export default FooterForm;
