import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
     :root {
          --main-white: #fff;
          --main-black: #000;
          --accent: #ffb803;
          --color: #232323;
          --transition: 250ms cubic-bezier(0.4, 0, 0.2, 1);
     }

     * {
          &::-webkit-scrollbar {
               width: 8px;             /* width of the entire scrollbar */

          }

          &::-webkit-scrollbar-track {
               background: var(--body-background); 
          }

          &::-webkit-scrollbar-thumb {
               background-color: var(--color);
               border-radius: 10px;       /* roundness of the scroll thumb */
               border: 1px solid var(--body-background);  /* creates padding around scroll thumb */

               &:hover {
                    background-color: var(--accent);
               }
          }
     }

     body {
          font-family: "Fixel", "Rubik", sans-serif;
          background-color: var(--main-white);
          color: var(--main-black);
          overflow-x: hidden;
          background-repeat: repeat;
          overflow: hidden;

          
     }    

     code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     svg {
          fill: inherit;
          stroke: inherit
     }
     
`;
