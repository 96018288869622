import css from "./burger-nav-list.module.css";
import { BurgerNavItem } from "./burger-nav-item/burger-nav-item";
import { nanoid } from "nanoid";
import React from "react";

export const BurgerNavList = ({ list }) => {
  return (
    <ul className={css["burger-menu-list"]}>
      {list.map(({ link, text }) => {
        let itemId = nanoid();
        return <BurgerNavItem key={itemId} link={link} text={text} />;
      })}
    </ul>
  );
};
