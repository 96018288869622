import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { GlobalStyle } from "./GlobalStyle";
import ReactModal from "react-modal";
import { Loader } from "./components/Loader/Loader";
import { Toaster } from "react-hot-toast";
import { toastOptions } from "./other/toastOptions";
import Layout from "./components/Layout/Layout";

ReactModal.setAppElement("#root");

const Home = lazy(() => import("./pages/home/Home.jsx"));

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <Suspense fallback={<Loader className="apploader" />}>
        <Routes>
          <Route path={"/"} element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="*" element={<Layout />} />
        </Routes>
      </Suspense>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={toastOptions}
        containerStyle={{
          top: 60,
          right: 20,
        }}
      />
    </>
  );
};
