import css from "./Footer.module.css";
import { MainLogo } from "../elements/main-logo/main-logo";
import FooterForm from "./footer-form/footer-form";
import React from "react";
import svg from "../../img/sprite.svg";
import { useLanguage } from "../../hooks/useLanguage.js";

export const Footer = ({ content }) => {
  const {
    schema: {
      footer: {
        form,
        contacts: { title, desc, email },
      },
    },
  } = useLanguage();

  return (
    <footer className={css["contacts"]} id="contacts">
      <div className={css["container"]}>
        <address className={css["contacts-data"]}>
          <div className={css["title-container"]}>
            <svg className={css["svg"]} width="25" height="25">
              <use href={`${svg}#plus`}></use>
            </svg>
            <p className={css["contact-label"]}>{title}</p>
          </div>
          <p className={css["contact-info"]}>{desc}</p>
          <p className={css["contact-label"]}>{email}</p>
          <a
            href="mailto:aviaua_social@proton.me"
            className={css["contact-info"]}
          >
            aviaua_social@proton.me
          </a>
          <MainLogo
            linkClass={css["footer-link-logo-desktop"]}
            svgClass={css["footer-svg-logo"]}
            link={"#"}
          />
        </address>
        <FooterForm content={form} onSubmit={(values) => console.log(values)} />
        <MainLogo
          linkClass={css["footer-link-logo"]}
          svgClass={css["footer-svg-logo"]}
          link={"#"}
        />
      </div>
    </footer>
  );
};
