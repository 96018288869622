import css from "./header-slogan-list.module.css";
import { HeaderSloganItem } from "./header-slogan-item";
import { nanoid } from "nanoid";
import React from "react";

export const HeaderSloganList = ({ list }) => {
  return (
    <ul className={css["header-slogan-list"]}>
      {list.map(({ text }) => {
        let itemId = nanoid();
        return <HeaderSloganItem key={itemId} text={text} />;
      })}
    </ul>
  );
};
