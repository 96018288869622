import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    burger: {
      isOpen: false,
    },
    header: {
      isOpen: true,
      isScroll: false,
    },
    language: "ua",
  },
  reducers: {
    setLanguage(state, { payload }) {
      state.language = payload;
    },
    setIsOpenBurger(state, { payload }) {
      state.burger.isOpen = payload;
    },
    setIsHeaderOpen(state, { payload }) {
      state.header.isOpen = payload;
    },
    setIsHeaderScroll(state, { payload }) {
      state.header.isScroll = payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setLanguage,
  setIsOpenBurger,
  setIsHeaderOpen,
  setIsHeaderScroll,
} = appSlice.actions;
