import { useSelector } from "react-redux";

export const useHeader = () => {
  const { isOpen, isScroll } = useSelector((state) => state.app.header);

  return {
    isOpen,
    isScroll,
  };
};
