import logo from "../../../img/sprite.svg";
import React from "react";

export const MainLogo = ({ link, linkClass, svgClass }) => {
  return (
    <a href={link} className={linkClass}>
      <svg className={svgClass} width="112" height="37">
        <use href={`${logo}#logo`}></use>
      </svg>
    </a>
  );
};
