import { useDispatch } from "react-redux";
import css from "./burger-nav-item.module.css";
import React from "react";
import { setIsOpenBurger } from "../../../../redux/app/slice.js";

export const BurgerNavItem = ({ link, text }) => {
  const dispatch = useDispatch();

  return (
    <li className={css["burger-menu-item"]}>
      <a
        onClick={() => dispatch(setIsOpenBurger(false))}
        href={link}
        className={css["burger-menu-link"]}
      >
        {text}
      </a>
    </li>
  );
};
