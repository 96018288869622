import css from "./burger-menu.module.css";
import { MainLogo } from "../elements/main-logo/main-logo";
import clsx from "clsx";
import { BurgerNavList } from "./burger-nav/burger-nav-list";
import React from "react";
import { svg } from "../../img/index.js";
import { useLanguage } from "../../hooks/useLanguage.js";
import { useBurger } from "../../hooks/useBurger.js";
import { useDispatch } from "react-redux";
import { setIsOpenBurger } from "../../redux/app/slice.js";

export const BurgerMenu = () => {
  const { isOpen } = useBurger();
  const dispatch = useDispatch();

  const {
    schema: {
      burger: { title, nav_list },
    },
  } = useLanguage();

  return (
    <div className={clsx(css["burger-block"], isOpen && css["is-open"])}>
      <div className={css["container"]}>
        <div className={css["burger-header"]}>
          <button
            onClick={() => dispatch(setIsOpenBurger(false))}
            className={`${css["burger-icon"]} ${css["burger-close"]}`}
          >
            <svg>
              <use href={`${svg}#icon-close`}></use>
            </svg>
          </button>
          <MainLogo
            link={"#"}
            linkClass={css["burger-link-logo"]}
            svgClass={css["burger-svg-logo"]}
          />
        </div>
        <BurgerNavList list={nav_list} />
        <h2 className={css["burger-title"]}>{title}</h2>
      </div>
    </div>
  );
};
