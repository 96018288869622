import { useSelector } from "react-redux";
import schema from "../languages.json";

export const useLanguage = () => {
  const { language } = useSelector((state) => state.app);

  return {
    language,
    schema: schema[language] || {},
  };
};
