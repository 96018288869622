import { Outlet } from "react-router-dom";
import { LayoutStyled, Main } from "./Layout.styled";
import { Footer } from "../Footer/Footer.jsx";
import { Header } from "../Header/Header.jsx";
import { BurgerMenu } from "../burger-menu/burger-menu.jsx";

const Layout = () => {
  return (
    <LayoutStyled>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
      <BurgerMenu />
    </LayoutStyled>
  );
};

export default Layout;
