export const toastOptions = {
  success: {
    style: {
      backgroundColor: "var(--body-background)",
      borderRadius: "var(--border-radius)",
      boxShadow: "0 0 5px 1px var(--accent)",
      color: "var(--color)",
      padding: "10px 20px",
      zIndex: "100",
    },
    duration: 4000,
  },
  loading: {
    style: {
      backgroundColor: "var(--body-background)",
      borderRadius: "var(--border-radius)",
      boxShadow: "0 0 5px 1px var(--accent)",
      color: "var(--color)",
      padding: "10px 20px",
      zIndex: "100",
    },
  },
  error: {
    style: {
      backgroundColor: "var(--body-background)",
      borderRadius: "var(--border-radius)",
      boxShadow: "0 0 5px 1px var(--accent)",
      color: "red",
      padding: "10px 20px",
      zIndex: "100",
    },
    duration: 10000,
  },
  info: {
    style: {
      backgroundColor: "var(--body-background)",
      borderRadius: "var(--border-radius)",
      boxShadow: "0 0 5px 1px var(--accent)",
      color: "var(--color)",
      padding: "10px 20px",
    },
    duration: 10000,
  },
};
