import css from "./Header.module.css";
import svg from "../../img/sprite.svg";
import React, { useEffect, useRef, useCallback } from "react";
import { HeaderSloganList } from "./header-slogan/header-slogan-list";
import { MainLogo } from "../elements/main-logo/main-logo";
import clsx from "clsx";
import { LangSelector } from "../elements/lang-selector/lang-selector";
import {
  setIsHeaderOpen,
  setIsHeaderScroll,
  setIsOpenBurger,
} from "../../redux/app/slice.js";
import { useLanguage } from "../../hooks/useLanguage.js";
import { useHeader } from "../../hooks/useHeader.js";
import { useDispatch } from "react-redux";

export const Header = () => {
  const {
    schema: {
      header: { slogan_list },
    },
  } = useLanguage();
  const dispatch = useDispatch();

  const { isOpen, isScroll } = useHeader();

  let lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let tempScroll = scrollTop - lastScrollTop.current;

    let tempUp = -20;
    let tempDown = 30;

    if (!isOpen && scrollTop <= 50) {
      dispatch(setIsHeaderOpen(true));
      return;
    }

    if (tempScroll < 0) {
      // scroll up
      if (!isOpen && tempScroll < tempUp) {
        dispatch(setIsHeaderOpen(true));
      }

      if (scrollTop <= 10 && isScroll) {
        dispatch(setIsHeaderScroll(false));
      }
    } else {
      //scroll down
      if (scrollTop >= 80 && !isScroll) {
        dispatch(setIsHeaderScroll(true));
      }

      if (scrollTop >= 100 && isOpen && tempScroll > tempDown) {
        dispatch(setIsHeaderOpen(false));
      }
    }

    lastScrollTop.current = scrollTop;
  }, [dispatch, isOpen, isScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <header
      className={clsx(
        css["header"],
        !isOpen && css["header-hidden"],
        isScroll && css["header-scroll"]
      )}
      id="header"
    >
      <div className={css["container"]}>
        <div className={css["imitation"]}></div>
        <button
          onClick={() => dispatch(setIsOpenBurger(true))}
          className={`${css["burger-icon"]} ${css["burger-open"]}`}
          type="button"
        >
          <svg>
            <use href={`${svg}#burger-menu`}></use>
          </svg>
        </button>
        {<HeaderSloganList list={slogan_list} />}
        <nav className={css["header-nav"]}>
          <MainLogo
            svgClass={css["header-svg-logo"]}
            linkClass={css["header-link-logo"]}
            link={"#header"}
          />
        </nav>
        <div className={css["lang-container"]}>
          <LangSelector />
        </div>
      </div>
    </header>
  );
};
